<template>
  <div>
    <div class="edit-info-container">
      <header-back
        path="/my-account"
        rootClassName="rootClassName"
      ></header-back>
      <div class="edit-info-container1">
        <h1 class="edit-info-text">Account Information</h1>
        <form class="w-full" @submit.prevent="updateProfile">
          <fieldset class="w-full" :disabled="isLoading">
            <input-box
              rootClassName="rootClassName1"
              text="Name"
              :textinputPlaceholder="isLoading ? 'Loading...' : null"
              v-model="name"
            ></input-box>
            <input-box
              rootClassName="rootClassName2"
              text="Phone Number"
              :textinputPlaceholder="isLoading ? 'Loading...' : null"
              v-model="phone"
            ></input-box>
            <input-box
              disabled
              :textinputPlaceholder="isLoading ? 'Loading...' : null"
              rootClassName="rootClassName3"
              v-model="email"
            ></input-box>
            <button
              type="submit"
              class="edit-info-btn-login rounded hover:bg-gray-700"
            >
              <span class="edit-info-text1">
                {{ isSubmitting ? 'Updating...' : 'Save Information' }}
              </span>
            </button>
          </fieldset>
        </form>
      </div>
    </div>
    <div v-if="isError" @click="closeMsg()" class="alert-err">
      <XClose :text="msg_error" />
    </div>
    <div v-if="isSukses" @click="closeSukses()" class="alert-err">
      <XSukses :text="msg_sukses" />
    </div>
    <Footer />
  </div>
</template>

<script>
  import HeaderBack from '../components/header-back.vue';
  import InputBox from '../components/input-box.vue';
  import Footer from '../components/footer';
  import Api from '../api';
  import XClose from '../components/x-close';
  import XSukses from '../components/x-sukses';
  import axios from 'axios';
  export default {
    name: 'EditInfo',
    props: {},
    data() {
      return {
        profile: null,
        isError: false,
        msg_error: null,
        isSukses: false,
        msg_sukses: null,
        profile: null,
        name: null,
        email: null,
        phone: null,
        isLoading: false,
        isSubmitting: false,
      };
    },
    async mounted() {
      await this.getProfile();
    },
    methods: {
      async getProfile() {
        try {
          this.isLoading = true;
          const {
            data: { data },
          } = await axios.get(Api.profileUrl);
          this.profile = data.user;
          this.name = this.profile.name;
          this.email = this.profile.email;
          this.phone = this.profile.phone;
        } catch (error) {
          throw new Error(error);
        } finally {
          this.isLoading = false;
        }
      },
      async updateProfile() {
        try {
          this.isSubmitting = true;
          if (this.name === '') {
            this.msg_error = 'Name is required';
            this.isError = true;
            return;
          } else if (this.phone === '') {
            this.msg_error = 'Phone Number is required';
            this.isError = true;
            return;
          } else {
            const res = await axios.put(`${Api.profileUrl}`, {
              name: this.name,
              email: this.email,
              phone: this.phone,
            });

            if (res.status === 200) {
              this.$store.commit('setProfile', res.data.data);
              this.$router.push('/my-account');
            }
          }
        } catch (error) {
          this.msg_error = error.response.data.message;
          this.isError = true;
        } finally {
          this.isSubmitting = false;
        }
      },
      closeMsg() {
        this.msg_error = null;
        this.isError = false;
      },
      openMsg(msg) {
        this.msg_error = msg;
        this.isError = true;
      },
      closeSukses() {
        this.msg_sukses = null;
        this.isSukses = false;
      },
    },
    components: {
      HeaderBack,
      InputBox,
      Footer,
      XClose,
      XSukses,
    },
  };
</script>

<style scoped>
  .alert-err {
    position: fixed;
    right: 20px;
    bottom: 30px;
    width: 300px;
    z-index: 10000;
  }
  .edit-info-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: #eae3d9;
    padding-bottom: 46px;
  }
  .edit-info-container1 {
    width: 492px;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .edit-info-text {
    color: #1f252c;
    font-family: Lora;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 24px;
  }
  .edit-info-btn-login {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 64px;
    display: flex;
    position: relative;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
    background-color: #1f252c;
  }
  .edit-info-text1 {
    color: #fff;
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 2.4px;
  }
  @media (max-width: 991px) {
    .edit-info-container1 {
      width: 100%;
      margin-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
  }
</style>
